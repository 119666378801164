.outformer {
    max-width: 100rem;
    margin: 0 3rem;
}

.kvizc {
    font-size: 5rem;
    margin-bottom: 2rem;
    text-align: center;
    color: white;
}

.former {
    display: flex;
    flex-direction: column;
}

.former p {
    color: white;
    font-size: 2rem;
    text-align: center;
}

.informer {
    display: flex;
    width: calc(50% - 10px);
    margin: 5px;
    min-height: 5rem;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    z-index: 999;
    text-align: center;
}

.informer label {
    color: white;
    font-size: 18px;
    padding: 1rem;
    cursor: pointer;
}

.vonal {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border: 0;
    border-top: 1px rgba(255, 255, 255, 0.5) solid;
    z-index: 69;
    width: 50%;
    margin: 1.5rem auto;
}

.optflexer {
    display: flex;
    flex-wrap: wrap;
}

.cirsq:checked {
    background: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==) no-repeat center center;
    background-size: 9px 9px;
}

.cirsq:focus {
    outline-color: transparent;
}

.totscore {
    color: white;
    font-size: 2rem;
    margin: 3rem 0 0 3rem;
}

.kvizcontent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    align-items: center;
}

.upformer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    align-items: center;
    border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.informer {
    border-radius: 5px;
    cursor: pointer;
}

.selected {
    background-color: #407072;
    color: white;
}

.gombkollekcio {
    display: flex;
    justify-content: space-around;
}