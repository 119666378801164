.sidebar h1 {
    text-align: left;
    margin: 2.2rem 0 2.2rem 2rem;
    color: white;
}

.href-ab {
    color: black;
    font-size: 2rem;
    padding: 5px;
}

.hrefb {
    color: white;
    font-size: 1.8rem;
    padding: 3px;
    padding-left: 2rem;
}

.hrefb:hover {
    background: linear-gradient(90deg, rgb(99, 99, 99) 0%, rgb(99, 99, 99) 100%)
}

.main {
    display: flex;
    background: #1d2a35;
    /*
    background: linear-gradient( 45deg,#130f35,rgba(19, 15, 53, 0.46) );
    background-color: #28807b;
    */
}

.fo {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    margin-left: 220px;
}

.lila {
    background-color: #1d2a35f1;
}

.padder {
    margin: 3rem 0!important;
}

/*
.sidebar {
    width: 220px;
        height: 100%;
        overflow-y: scroll;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    
        background: rgb(21,32,43);
        background: linear-gradient(0deg, rgba(21,32,43,1) 90%, rgba(29,42,53,1) 100%);

    left: 0;
}
*/

.sidebar {
    background: rgb(21, 32, 43);
    background: linear-gradient(0deg, rgba(21, 32, 43, 1) 90%, rgba(29, 42, 53, 1) 100%);
    color: #ccc;
    height: 100%;
    width: 100%;
    background-color: #E7E9EB;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.butyok {
    padding-top: 75px;
    position: fixed;
    top: 0;
    padding-bottom: 0;
    height: 100%;
    width: 220px;
    background-color: transparent;
    box-sizing: border-box;
    display: block;
    z-index: 70;
}

.sidebarwrapper {
    display: block;
    height: 100%;
    width: 200px;
    position: fixed !important;
    z-index: 70;
    overflow: auto;
    box-sizing: border-box;
}


.container-ab {
    max-width: 1170px;
    margin: auto;
    padding: 1.5rem 0;
}

.row-ab {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2rem;
}

ul {
    list-style: none;
    padding-inline-start: 0px;
}

.footer-ab {
    padding: 1rem 0;
    background-color: #1d2a35f1;
}

.footer-col-ab {
    width: 25%;
}

.footer-col-ab h4 {
    font-size: 19px;
    color: white;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 20px;
    position: relative;
}

/*
.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #e91e63;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}
*/

.footer-col-ab ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col-ab ul li .href-ab {
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.footer-col-ab ul li .href-ab:hover {
    color: white;
    padding-left: 8px;
}

.footer-col-ab .social-links-ab .href-ab {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: white;
    transition: all 0.5s ease;
}

.footer-col-ab .social-links-ab .href-ab:hover {
    background-color: white;
    color: #1d2a35f1;
}

.fab:hover {
    z-index: 800;
}

.social-links-ab {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1.5rem 3rem 2.5rem 3rem;
    width: 100%;
    border-right: 1px solid #38444d;
}

.content h1 {
    font-size: 4rem;
    color: white;
    margin: 1.5rem 0 0.8rem 0;
}

.content h2 {
    font-size: 2.4rem;
    color: white;
}

.content p {
    font-size: 1.8rem;
    margin: 14px 0;
    color: white;
}

.majonator {
    margin: 5px 0 !important;
}

.majonatora {
    margin: 2.5px 0 !important;
}

.content li {
    font-size: 1.8rem;
    color: white;
}

.upper {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #38444d;
    background-color: #1d2a35f1;
    min-height: 80vh;
    flex-grow: 1;
    padding-top: 75px;
}

.addv {
    width: 21%;
    min-width: 240px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border: 0;
    margin: 20px -16px;
    border-top: 1px solid #38444d;
    z-index: 69;
}

.szurke {
    display: block;
    background-color: #4a5761;
    margin-top: 18px;
    border-radius: 0.8rem;
    padding: 16px;
}

.logo {
    width: 2rem;
}

.ascx {
    color: white !important;
    font-size: 1.8rem;
    padding: 0;
    text-decoration: underline;
    text-decoration-color: rgb(189, 189, 189);
}

.low-25415 {
    font-size: 1.9rem;
    margin-bottom: 0;
}

.low-25416 {
    margin-top: 0;
}

pre {
    font-family: inherit;
    margin: 0.2rem;
    white-space: pre-wrap;
}

.btna:hover {
    background: white;
    color: #4a5761;
    transition: all 0.25s ease-out;
}

.btnb:hover {
    background: #27ca8e;
    border: #27ca8e 1px solid;
    color: #1d2a35f1;
    transition: all 0.25s ease-out;
}

#scrollergyerek::-webkit-scrollbar-track {
    background: linear-gradient(0deg, rgba(21, 32, 43, 1) 90%, rgba(29, 42, 53, 1) 100%);
}

.alatti {
    display: none;
}

.aza {
    font-weight: 600;
}

.azam {
    font-weight: 600;
    color: rgb(214, 214, 214) !important;
}

.robert102 img {
    top: 0 !important;
}

.robert102 {
    justify-content: center;
}

.robert103 {
    flex-direction: column;
    align-items: baseline !important;
}

.dezord {
    color: rgb(149, 198, 255);
}

span.frac {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

span.frac>sup,
span.frac>sub {
    display: block;
    font: inherit;
    padding: 0 0.3em;
}

span.frac>sup {
    border-bottom: 0.08em solid;
}

span.frac>span {
    display: none;
}




@media(max-width: 1015px) {
    .alatti {
        display: block;
        position: fixed !important;
        top: 75px;
        background-color: #161f27;
        width: 100%;
        padding: 0.5rem 0;
        z-index: 71;
    }

    .flexnuni {
        display: flex;
        margin-left: 1.5rem;
    }

    .flexnuni .bar {
        width: 16.6px;
        height: 2px;
    }

    .upper {
        padding-top: 91px;
    }

    #header {
        height: 65px;
    }

    .alatti {
        top: 65px;
    }

    .hamburger-active2 .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger-active2 .bar:nth-child(1) {
        transform: translateY(6.75px) rotate(45deg);
    }

    .hamburger-active2 .bar:nth-child(3) {
        transform: translateY(-6.75px) rotate(-45deg);
    }

    .sidebarwrapper {
        display: none;
    }

    .minisidebar {
        display: block;
    }

    .sidebar {
        width: 220px;
        height: 100%;
        overflow-y: scroll;

        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        z-index: 70;

        background: rgb(21, 32, 43);
        background: linear-gradient(0deg, rgba(21, 32, 43, 1) 90%, rgba(29, 42, 53, 1) 100%);

        left: 0;
    }

    .sidebar h1 {
        text-align: left;
        margin: 2.2rem 0 2.2rem 2rem;
        color: white;
    }

    .butyok {
        padding-top: 101px;
    }
}




@media(max-width: 767px) {
    .footer-col-ab {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media(max-width: 574px) {
    .footer-col-ab {
        width: 100%;
        text-align: center;
    }

    .footer-col-ab h4::before {
        display: none;
    }
}

@media(max-width: 540px) {
    .footer-c-ab {
        padding-top: 15rem;
    }
}

@media(max-width: 1015px) {

    .fo {
        margin-left: 0;
    }

    .addv {
        display: none;
    }
}

@media(max-width: 500px) {
    .content h1 {
        font-size: 3rem;
    }

    .content h2 {
        font-size: 2.2rem;
    }
}

@media(max-width: 250px) {
    .content h1 {
        font-size: 2.5rem;
    }

    .content h2 {
        font-size: 1.8rem;
    }
}

@media(max-width: 225px) {
    .fo {
        width: auto;
    }
}

@media(min-width: 575px) {
    .footer-col-ab ul li .href-ab {
        width: 90%;
    }
}