.teljeskepernyos {
    position: relative;
    margin: 0 auto 5rem auto;
    padding: 0 2.5rem;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: white;
}

.teljeskepernyos h2 {
    font-size: 3.5rem;
}

.dobozolo {
    text-align: center;
    max-width: 800px;
}

.teljeskepernyos p {
    font-size: 2rem;
}

.footer-col h3{
	font-size: 19px;
	color: white;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}

@media(max-width: 570px) {
    .teljeskepernyos h2 {
        font-size: 2.5rem;
    }

    .teljeskepernyos p {
        font-size: 1.5rem;
    }
    .teljeskepernyos {
        margin: 5rem auto 5rem auto;
    }
}

@media(max-width: 412px) {
    .teljeskepernyos h2 {
        font-size: 2rem;
    }
}

@media(max-width: 220px) {
    .teljeskepernyos h2 {
        font-size: 1.5rem;
    }

    .teljeskepernyos p {
        font-size: 1rem;
    }
}

.kiemelo {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 10rem 0 5rem 0;
}

.kiemelo {
    color: white;
}

.container55 {
    width: 100%;
}

.eye {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background: #CCC;
}

.eye:after {
    /*pupil*/
    position: absolute;
    bottom: 17px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 50%;
    content: " ";
}

.kovaszos {
    z-index: 20!important;
}