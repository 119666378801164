/*
.csomagolopapir {
    background-image: linear-gradient(to right, #141e30, #243b55);
    position: relative;
    overflow: hidden;
}
*/

.savas {
    padding: 0 4rem 0 4rem;
    flex-grow: 1;
}

.negyszog {
    margin: 0 auto 0 auto;
    width: 100%;
    max-width: 120rem;
}

.fuggvas {
    margin: 9rem 0 6rem 0;
}

.cumo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.topwrapper {
    text-align: center;
}

.fuggvas2 {
    padding: 2.5rem 0 2.5rem 0;
}

.padlas {
    margin-bottom: 3rem;
}

.padlasajto {
    width: 100%;
    max-width: 120rem;
}

.roadtitle {
    letter-spacing: 0.05rem;
    font-size: 7.5rem;
    font-weight: 400;
    line-height: 1;
    color: white;
    margin: 5rem 0 4rem 0;
}

.pince {
    width: 100%;
}

.pinceajto {
    width: 100%;
    position: relative;
}

.pinceajto img {
    width: 100%;
    max-width: none;
    position: relative;
    object-fit: cover;
    height: auto;
}

.stilo {
    display: block;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
}

.mobilkeszulek {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}



.allomas {
    margin-top: 0;
    margin-left: 0;
    display: block;
    top: 76%;
    left: 48%;
    font-size: 4.2rem;
    position: absolute;
    bottom: auto;
    right: auto;
}

.intheallomas {
    width: 6.5rem;
    height: 6.5rem;
    cursor: pointer;
    transform-style: preserve-3d;
    background-color: ghostwhite;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    font-family: Kanit, sans-serif;
    line-height: 1em;
    transition: transform 0.4s;
    display: flex;
}

/*
.intheallomas:hover {
    background-color: rgb(119, 158, 173);
    transition: all 0.2s ease;
}
*/

.intheallomas:before {
    content: '';
    /*background: linear-gradient(45deg, #ff0000, #FF00C8, #7A00FF, #002BFF, #7a00ff, #ff00c8, #FF0000, #002BFF);*/
    background: linear-gradient(45deg, #9064A5, #598A73, #22A713, #FFDD00, #FF7700, #007D2C, #4975BB, #A3B01D, #4A1717);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 100px;
}

.intheallomas:active {
    color: #000;
}

.intheallomas:active:after {
    background: white;
}

.intheallomas:hover:before {
    opacity: 1;
}

.intheallomas:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    left: 0;
    top: 0;
    border-radius: 100px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.allszam {
    font-weight: 400;
    color: black;
    user-select: none;
}

.allomas2 {
    margin-top: 0;
    margin-left: 0;
    display: block;
    top: 59%;
    left: 56%;
    font-size: 4.2rem;
    position: absolute;
    bottom: auto;
    right: auto;
    cursor: pointer;
}

.allomas3 {
    margin-top: 0;
    margin-left: 0;
    display: block;
    top: 49%;
    left: 60%;
    font-size: 4.2rem;
    position: absolute;
    bottom: auto;
    right: auto;
    cursor: pointer;
}

.allomas4 {
    margin-top: 0;
    margin-left: 0;
    display: block;
    top: 44%;
    left: 43%;
    font-size: 4.2rem;
    position: absolute;
    bottom: auto;
    right: auto;
    cursor: pointer;
}

.allomas5 {
    margin-top: 0;
    margin-left: 0;
    display: block;
    top: 44.5%;
    left: 27.5%;
    font-size: 4.2rem;
    position: absolute;
    bottom: auto;
    right: auto;
    cursor: pointer;
}

.allomas6 {
    margin-top: 0;
    margin-left: 0;
    display: block;
    top: 28%;
    left: 39%;
    font-size: 4.2rem;
    position: absolute;
    bottom: auto;
    right: auto;
    cursor: pointer;
}

.alltarto1 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.15s linear;
    margin-top: -5px;
    margin-left: 0;
    left: calc(50% - 140px);
    top: 110%;
    bottom: auto;
    right: auto;
    z-index: 100;
    width: 21rem;
    padding: 1rem 1.5rem 1.5rem;
    height: auto;
    backdrop-filter: blur(24px);
    text-align: left;
    /*background-color: rgba(14, 76, 94, .8);*/
    border-radius: 1.5rem;
    position: absolute;
    box-shadow: 1px 1px 16px 2px rgba(14, 8, 14, .16);
    /*
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 0.2s ease-out;
    */
}

.alltarto2 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.15s linear;
    margin-top: -5px;
    margin-left: 0;
    left: calc(50% - 160px);
    top: 110%;
    bottom: auto;
    right: auto;
    z-index: 100;
    width: 20rem;
    padding: 1rem 1.5rem 1.5rem;
    height: auto;
    backdrop-filter: blur(24px);
    text-align: left;
    border-radius: 1.5rem;
    position: absolute;
    box-shadow: 1px 1px 16px 2px rgba(14, 8, 14, .16);
}

.alltarto3 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.15s linear;
    margin-top: -5px;
    margin-left: 0;
    left: calc(50% - 160px);
    top: 110%;
    bottom: auto;
    right: auto;
    z-index: 100;
    width: 20rem;
    padding: 1rem 1.5rem 1.5rem;
    height: auto;
    backdrop-filter: blur(24px);
    text-align: left;
    border-radius: 1.5rem;
    position: absolute;
    box-shadow: 1px 1px 16px 2px rgba(14, 8, 14, .16);
}

.alltarto4 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.15s linear;
    margin-top: -5px;
    margin-left: 0;
    left: calc(50% - 160px);
    top: 110%;
    bottom: auto;
    right: auto;
    z-index: 100;
    width: 20rem;
    padding: 1rem 1.5rem 1.5rem;
    height: auto;
    backdrop-filter: blur(24px);
    text-align: left;
    border-radius: 1.5rem;
    position: absolute;
    box-shadow: 1px 1px 16px 2px rgba(14, 8, 14, .16);
}

.alltarto5 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.15s linear;
    margin-top: -5px;
    margin-left: 0;
    left: calc(50% - 160px);
    top: 110%;
    bottom: auto;
    right: auto;
    z-index: 100;
    width: 20rem;
    padding: 1rem 1.5rem 1.5rem;
    height: auto;
    backdrop-filter: blur(24px);
    text-align: left;
    border-radius: 1.5rem;
    position: absolute;
    box-shadow: 1px 1px 16px 2px rgba(14, 8, 14, .16);
}

.alltarto6 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.15s linear;
    margin-top: -5px;
    margin-left: 0;
    left: calc(50% - 160px);
    top: 110%;
    bottom: auto;
    right: auto;
    z-index: 100;
    width: 20rem;
    padding: 1rem 1.5rem 1.5rem;
    height: auto;
    backdrop-filter: blur(24px);
    text-align: left;
    border-radius: 1.5rem;
    position: absolute;
    box-shadow: 1px 1px 16px 2px rgba(14, 8, 14, .16);
}

.allomas:hover .alltarto1 {visibility: visible; opacity: 1;}
.allomas2:hover .alltarto2 {visibility: visible; opacity: 1;}
.allomas3:hover .alltarto3 {visibility: visible; opacity: 1;}
.allomas4:hover .alltarto4 {visibility: visible; opacity: 1;}
.allomas5:hover .alltarto5 {visibility: visible; opacity: 1;}
.allomas6:hover .alltarto6 {visibility: visible; opacity: 1;}

.terminal h3 {
    font-size: 2rem;
    margin: 3px;
    color: white;
    font-weight: 400;
}

.terminal .success:hover{
    color: black!important;
}

@media(max-width: 1100px){
    .intheallomas {
        width: 6rem;
        height: 6rem;
        font-size: 3.75rem;
    }
    .terminal {
        width: 19rem;
    }
    .terminal h3 {
        font-size: 1.8rem;
    }
    .savas {
        padding: 0;
    }
}

@media(max-width: 1000px){
    .intheallomas {
        width: 5.5rem;
        height: 5.5rem;
        font-size: 3.5rem;
    }
    .terminal {
        width: 17rem;
    }
    .terminal h3 {
        font-size: 1.6rem;
    }
}

@media(max-width: 900px){
    .intheallomas {
        width: 5rem;
        height: 5rem;
        font-size: 3rem;
    }
    .terminal {
        width: 15rem;
        left: calc(50% - 100px);
    }
    .terminal h3 {
        font-size: 1.4rem;
    }
}

@media(max-width: 800px){
    .intheallomas {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 2.8rem;
    }

}

@media(max-width: 750px){
    .intheallomas {
        width: 4.2rem;
        height: 4.2rem;
        font-size: 2.6rem;
    }
}

@media(max-width: 700px){
    .intheallomas {
        width: 3.8rem;
        height: 3.8rem;
        font-size: 2.6rem;
    }
    .roadtitle {
        font-size: 5rem;
    }
}

@media(max-width: 650px){
    .intheallomas {
        width: 3.5rem;
        height: 3.5rem;
        font-size: 2.3rem;
    }
}

@media(max-width: 600px){
    .intheallomas {
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
    }
}

@media(max-width: 550px){
    .intheallomas {
        width: 2.7rem;
        height: 2.7rem;
        font-size: 1.8rem;
    }
}

@media(max-width: 550px){
    .intheallomas {
        width: 2.3rem;
        height: 2.3rem;
        font-size: 1.5rem;
    }
}

@media(max-width: 500px){
    .roadtitle {
        font-size: 3rem;
    }
}

@media(max-width: 450px){
    .intheallomas {
        width: 2rem;
        height: 2rem;
        font-size: 1.2rem;
    }
}

@media(max-width: 400px){
    .intheallomas {
        width: 1.8rem;
        height: 1.8rem;
        font-size: 1rem;
    }
}

@media(max-width: 375px){
    .intheallomas {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.8rem;
    }
}

@media(max-width: 350px){
    .intheallomas {
        width: 1.25rem;
        height: 1.25rem;
        font-size: 0.75rem;
    }
    .roadtitle {
        font-size: 2rem;
    }
}






.allomascontent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    padding-top: 75px;
    background-image: linear-gradient(to right, #141e30, #243b55);
    color: white;
    align-items: center;
}

.allcontentheader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    align-items: center;
    border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.allcontentheader h1 {
    font-size: 5rem;
    margin-bottom: 2rem;
}

.allcontentheader p {
    font-size: 2rem;
    margin-top: 1rem;
    max-width: 120rem;
}

.allcontentcontent {
    max-width: 120rem;
}

.allcontentcontent h2 {
    margin-left: 2rem;
    font-size: 3rem;
}

.allcontentcontent h3 {
    margin-left: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2.2rem;
    color: rgba(255, 255, 255, 0.85);
}

.h3alter {
    margin-left: 3rem!important;
    margin-right: 3rem!important;
}

.allcontentcontent p {
    margin: 0 2rem;
    font-size: 1.8rem;
}

.lowernavbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem;
}

.allmini {
    font-size: 1.4rem!important;
}

.incstate {
    margin: 0 3rem;
}

.irodalom {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.halfirodalom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4rem;
}

.halfirodalom img {
    max-width: 200px;
}

.dsfgdfgsdhg55 {
    margin: 0 2rem;
}

.listdivle {
    max-width: 110rem;
    border-radius: 8px;
    background-color: #0e142e;
    margin: 0 2rem;

    display: flex;
    flex-direction: column;
}

.listdivelem {
    height: 5.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.baronar {
    margin: 0;
    border-top: 1px solid #1c2d42;
}

.listdivelem:hover {
    background-color: rgb(22, 33, 59);
    cursor: pointer;
    transition: all 0.15s ease;
}

.elsoked:hover {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.lastked:hover {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.centerer {
    text-align: center;
}

@media(max-width: 1015px){
    .irodalom {
        flex-direction: column;
    }
    .halfirodalom p {
        margin-top: 3rem;
    }
    .halfirodalom {
        margin: 0;
    }
}