@font-face {
    font-family: szabalyfont;
    src: url(boldfont.ttf);
}

@font-face {
    font-family: szabalypfont;
    src: url(nicefont.ttf);
}

.szabalyokmain {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.egyszabaly {
    max-width: 954px;
    line-height: 1.75;
    margin-top: 1rem;
    margin: 0 2rem;
}

.egyszabaly h1 {
    font-size: 3.5rem;
    font-weight: 300;
    margin-bottom: 0;
    font-family: 'Barlow', sans-serif;
    color: white;
}


.egyszabaly p {
    color: white;
    font-size: 18px;
    margin: 16px 0;
}

.miniad {
    max-width: 954px;
    background-color: #f3f4f898;
    border-radius: 12px;
    margin: 4rem 2rem 1.5rem 2rem;
}

.miniad p {
    color: #515260;
    font-size: 1.4rem;
    font-weight: 100;
    font-family: sans-serif;
    padding: 14px;
}

.miniad h1 {
    padding: 10px 25px;
}

.miniad hr {
    margin: 0 2rem;
    border-top: 1px solid #999;
}

.kivetel122 {
    margin: 0 2rem !important;
}

.lastszabaly {
    margin-bottom: 3.345rem;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.covering {
    position: relative;
    max-width: 1190px;
    margin: 0 auto;
    padding: 0 2rem;
    flex-grow: 1;
}

.titldiv {
    display: flex;
    justify-content: center;
    text-align: center;
}

.titldiv h1 {
    font-size: 5rem;
    margin: 5rem 0;
    color: white;
}

.covering hr {
    margin: 0;
    border-top: 1px solid #999;
}

@media(max-width: 550px) {
    .egyszabaly p {
        font-size: 15.5px;
    }
    .egyszabaly h1 {
        font-size: 25px;
    }
    .titldiv h1 {
        font-size: 4rem;
    }    
}

@media(max-width: 400px) {
    .egyszabaly h1 {
        font-size: 20px;
    }
    .titldiv h1 {
        font-size: 3rem;
    }    
}

@media(max-width: 220px) {
    .egyszabaly h1 {
        font-size: 15px;
    }
    .egyszabaly p {
        font-size: 12px;
    }
    .titldiv h1 {
        font-size: 2rem;
    }    
}