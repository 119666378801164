.kerdescont {
    position: relative;
    max-width: 1190px;
    margin: 0 auto 5rem auto;
    padding: 0 2rem;
    flex-grow: 1;
}

.gyikcim {
    display: flex;
    justify-content: center;
    text-align: center;
}

.gyikcim h1 {
    color: white;
    font-size: 5rem;
}

.spandd {
    background-color: #1e1e1e !important;
    height: 70px;
}

.spandd p {
    color: white;
    font-size: 1.8rem;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 5px;
}

.leiras {
    color: white;
    font-size: 1.5rem !important;
    font-family: 'Source Sans Pro', sans-serif !important;
}

@media(max-width: 390px) {
    .spandd p {
        font-size: 1.4rem;
    }
}

@media(max-width: 250px) {
    .spandd p {
        font-size: 1.2rem;
    }
}

.kapcscikk {
    position: relative;
    max-width: 1190px;
    margin: 3rem auto 5rem auto;
    padding: 0 2rem;
    flex-grow: 1;
}

.kapcsolatkeret {
    display: flex;
    justify-content: center;
    text-align: center;
}

.kapcsolatkeret h1 {
    color: white;
    font-size: 5rem;
}

.formaloember {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form__input {
    border: none;
    border-bottom: 1px solid #ddd;
    background: none;
    width: 600px;
    max-width: 100%;
    margin: 1rem 0;
    padding: 1rem;
    color: white;
}

@media(max-width: 700px) {
    .form__input {
        width: 400px;
    }
}

@media(max-width: 500px) {
    .form__input {
        width: 300px;
    }
}

@media(max-width: 400px) {
    .form__input {
        width: 250px;
    }
}

textarea {
    resize: none;
    height: 12rem;
}

.respbtner {
    padding: 6.875px 5rem;
}

.wowowoweeee {
    display: flex;
    flex-direction: column;
    align-items: center;
}







.szovegwrappener {
    position: relative;
    max-width: 1190px;
    margin: 1rem auto 5rem auto;
    padding: 0 2rem;
    flex-grow: 1;
}

.cimwrappener {
    display: flex;
    justify-content: center;
    text-align: center;
}

.cimwrappener h1 {
    color: white;
    font-size: 5rem;
}

.contentumwrap {
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.contentumwrap p {
    color: white;
    font-size: 1.8rem;
    margin: 0.5rem 0;
}

.contentumwrap h2 {
    color: white;
    font-size: 2.5rem;
    margin: 1rem 0;
}

@media(max-width: 480px) {
    .contentumwrap p {
        font-size: 1.6rem;
    }
    .contentumwrap h2 {
        font-size: 2.3rem;
    }
}

@media(max-width: 390px) {
    .contentumwrap p {
        font-size: 1.5rem;
    }
    .contentumwrap h2 {
        font-size: 2rem;
    }
    .cimwrappener h1 {
        font-size: 4rem;
    }
}

@media(max-width: 380px) {
    .cimwrappener h1 {
        font-size: 3rem;
    }
    .contentumwrap p {
        font-size: 1.3rem;
    }
    .contentumwrap h2 {
        font-size: 1.75rem;
    }
}

@media(max-width: 330px) {
    .cimwrappener h1 {
        font-size: 2.5rem;
    }
    .contentumwrap p {
        font-size: 1.1rem;
    }
    .contentumwrap h2 {
        font-size: 1.5rem;
    }
}

@media(max-width: 285px) {
    .cimwrappener h1 {
        font-size: 2.25rem;
    }
    .contentumwrap p {
        font-size: 1rem;
    }
    .contentumwrap h2 {
        font-size: 1.25rem;
    }
}





.adatkbox {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.adatkbox p {
    color: white;
    font-size: 1.7rem;
    margin-right: 2rem;
}


.checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #6e79d6;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
    background-color: #535db3;
}

@media (max-width: 360px) {  /*@media only screen and (max-width: 360px) {*/
    .g-recaptcha {
        transform:scale(0.77);
        transform-origin:1 0;
    }
    .adatkbox {
        flex-direction: column;
    }
    .wowowoweeee {
        width: 200px;
        display: flex;
        align-items: center;
    }
    .form__input {
        width: 225px;
    }
}


@media (max-width: 290px) {
    .form__input {
        width: 200px;
    }
}

@media (max-width: 270px) {
    .form__input {
        width: 175px;
    }
}

@media (max-width: 250px) {
    .form__input {
        width: 150px;
    }
    .g-recaptcha {
        transform:scale(0.60);
        transform-origin:1 0;
    }
    .kapcsolatkeret h1 {
        font-size: 4rem;
    }
}