video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.vid-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.4) 100%);
}

.centerer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: inherit;
}

.vid-container > .centerer > h1 {
    color: white;
    font-size: 70px;
}

@media(max-width: 620px){
    .vid-container > .centerer > h1 {
        font-size: 60px;
    }
}

@media(max-width: 535px){
    .vid-container > .centerer > h1 {
        font-size: 50px;
    }
}

@media(max-width: 430px){
    .vid-container > .centerer > h1 {
        font-size: 40px;
    }
}

@media(max-width: 345px){
    .vid-container > .centerer > h1 {
        font-size: 30px;
    }
}

@media(max-width: 280px){
    .vid-container > .centerer > h1 {
        font-size: 20px;
    }
}