.ttl {
    font-size: 3rem;
    color: rgb(255, 255, 255);
    padding: 0 30px 0 20px;
    z-index: 985;
  }

.linktrans, a {
    text-decoration: none;
    color: white
}

#header {
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  /*background-color: #1d2a35;*/
  height: 75px;
  width: 100%;
  /*box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);*/
  position: absolute;
  /*background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);*/
  top: 0;
  z-index: 980;
}

header .linktrans, header a {
  font-size: 2rem;
  color: white;
  padding: 25px 15px 25px 15px;
}

header .linktrans:hover, header a:hover:not(:first-child){
  color:rgb(209, 209, 209);
  transition: color 0.15s ease-out;
}

.fa {
  display: inline;
  font-size: 18px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.flexfasz {
  display: none;
  align-items: center;
  z-index: 985;
}

.hamnav {
  display: none;
}

.hamnav-active {
  display: flex;
  position: fixed;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: 100%;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  font-size: medium;
  padding-bottom: 2rem;
  z-index: 999;
  top: 75px;
  padding-top: 2rem;

  animation-name: dropfasz;
  animation-duration: 0.4s;
}

.hamnav-active .hamref {
  background: rgb(51, 51, 51);
  color: white;
  width: 95%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  user-select: none;
}

.hamnav-active .hamref:hover {
  background-color: #dfd9d98a;
  transition: background-color 0.2s ease-out;
}


.egypan {
  display: none;
}

.egypan-active {
  display: flex;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.644) 0%,rgba(0, 0, 0, 0.644) 100%);
  flex-direction: row;
  text-decoration: none;
  width: 100%;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  font-size: medium;
  padding-bottom: 2rem;
  z-index: 999;
  top: 75px;
  padding-top: 2rem;
}

.egypan-active .egyref {
  padding: 0 1.5rem;
}


.fogalomtar::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 209.203px);
  z-index: -9999;
}

.fogalomtar {
  position: fixed!important;
  background-color: #1d2a35;
}

.konyvek {
  position: relative!important;
  /*background-color: #1d2a35;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 16%);*/
}

.logincucc {
  display: none!important;
}

.registercucc {
  display: none!important;
}

/**/
.sima::-webkit-scrollbar-track {
  background-color: #130f35;
}
.zold::-webkit-scrollbar-track {
  background-color: #243b55;
}
.lila::-webkit-scrollbar-track {
  background-color: #1d2a35;
}
.kek::-webkit-scrollbar-track {
  background: rgb(27,49,50);
  background: linear-gradient(180deg, rgb(27, 50, 51) 0%, rgb(15, 33, 34) 100%);
}
.sotet::-webkit-scrollbar-track {
  background-color: #243b55;
}
/**/

.sotet {
  background: linear-gradient(to right, #141e30, #243b55);
  background-size: cover;
}

.kek {
  /* background: rgb(9, 19, 20); */
  background: rgb(27,49,50);
  background: linear-gradient(180deg, rgb(27, 50, 51) 0%, rgb(15, 33, 34) 100%);
}

#header img{
  margin-left: auto;
  margin-right: 3.5rem;
  cursor: pointer;
}

.keddc {
  position: fixed!important;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%)!important;
}



.dropdown12 {
  display: none;
  position: absolute;
  background-color: #1d2a35;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
}

.drop12alt {
  padding: 8px 30px;
  font-size: 18px;
  border-radius: 8px;
}

.drop12alt:hover {
  background: #ffffff10;
  transition: all 0.15s;
}

.dropfall13:hover .dropdown12 {
  display: block;
}

.dropcolorszabalyok {
  background-color: inherit;
}

.szabalyok {
  position: relative!important;
}

.zold {
  background-image: linear-gradient(to right, #141e30, #243b55);
}


.sima {
  background: linear-gradient(to right, #141e30, #243b55);
  background-size: cover;
}

.transdropcolor {
  background: rgba(0, 0, 0, 0.61);
}

@media(min-width: 1015px){
  .hamnav-active {
    display: none;
  }
}

@media(max-width: 1015px){

  .egypan-active {
    display: none;
  }

  #header img {
    display: none;
  }

  .kkkdc {
    position: fixed!important;
  }

  .linktrans {
    display: none;
  }

  .nyil {
    display: none;
  }

  .flexfasz {
    display: flex;
    margin-right: 2rem;
  }
  
  .hamburger{
    display: block;
  }
  
  .hamburger-active {
    cursor: pointer;
  }

  .hamburger-active .bar:nth-child(2){
    opacity: 0;
  }
  
  .hamburger-active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger-active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }

  .hamnav-active {
    position: absolute;
    top: 65px;
  }

  #header {
    justify-content: space-between;
  };

  .transgyerek {
    background: transparent;
  }
  
  .greygyerek {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  }
}

@media(max-width: 314px){
  .ttl {
    font-size: 2rem;
  }
}

@media(max-width: 248px){
  .ttl {
    font-size: 1.5rem;
  }
}

@media(max-width: 208px){
  .ttl {
    font-size: 1rem;
  }
}