.carddiv {
    width: 100%;
    background-color: white;
}

.cardalt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50rem;
    color: white;
}

.cardalt h2 {
    font-size: 4rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.cardaltpad {
    padding-top: 10rem;
    background: #130f35;
}

.left {
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    flex-direction: column;
    width: 45%;
    /*text-align: center;*/
}

.right {
    width: 45%;
    display: flex;
    justify-content: center;
}

.sidepic {
    width: 65%;
    max-width: 765px;
}

.cardalt div p {
    font-size: 2rem;
    font-weight: 500;
    /*padding: 0 60px;*/
}

/*
.btn {
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    border-radius: 2rem;
}

.success {
    background-color: #04AA6D;
}
*/
.btn {
    border: 1px solid;
    color: white;
    padding: 0.6875rem 1.5rem;
    cursor: pointer;
    border-radius: 1rem;
    border-color: rgba(255, 255, 255, 0.3);
    background: transparent;
    z-index: 9999;
}

.btn0:hover {
    background: white;
    color: #130f35;
    transition: all 0.25s ease-out;
}

.aa01 {
    background-color: #130f35; /* #3987B9; */
}

.aa02 {
    background-color: #130f35; /*#6bbbfc;*/
}

.aa03 {
    background-color: #130f35;  /*#3988b9;*/
}

.aa04 {
    background-color: #130f35;  /*#6bbbfc;*/
}

.remove561188311 {
    cursor: default;
}

.container-a {
    max-width: 1170px;
    margin: auto;
    padding: 1.5rem 0;
}

.row-a {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2rem;
}

ul {
    list-style: none;
    padding-inline-start: 0px;
}

.footer-c-a {
    /*background-color: #130f35;;*/
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(19,15,53,1) 100%);
    padding: 4rem 0 3rem 0;
}

.footer-col-a {
    width: 25%;
}

.footer-col-a h3 {
    font-size: 19px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
}

/*
.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #e91e63;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}
*/

.href-a {
    color: black;
    font-size: 2rem;
    padding: 5px;
}

.footer-col-a ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col-a ul li .href-a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.footer-col-a ul li .href-a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.footer-col-a .social-links-a .href-a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
}

.footer-col-a .social-links-a .href-a:hover {
    color: #24262b;
    background-color: #ffffff;
}

.social-links-a {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media(max-width: 767px) {
    .footer-col-a {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media(max-width: 574px) {
    .footer-col-a {
        width: 100%;
        text-align: center;
    }

    .footer-col-a h3::before {
        display: none;
    }
}

@media(max-width: 540px) {
    .cardalt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40rem;
        color: white;
    }

    .cardaltpad {
        padding-bottom: 18rem;
    }

    .cardalt h2 {
        text-align: center;
    }

    .cardalt p {
        text-align: center;
        padding: 0 2rem 0 2rem;
    }

    .footer-c-a {
        padding-top: 15rem;
    }

    .cardalt div {
        width: 100%;
    }

    .cardalt div p {
        margin-top: 30px;
    }

    .left {
        align-items: center;
    }
}

@media(max-width: 280px) {
    .cardalt div p {
        font-size: 1.5rem;
    }

    .cardalt div h2 {
        font-size: 3rem;
    }
}

@media(min-width: 575px) {
    .footer-col-a ul li .href-a {
        width: 90%;
    }
}