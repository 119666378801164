.container{
	max-width: 1170px;
	margin:auto;
	padding: 1.5rem 0;
}
.row{
	display: flex;
	flex-wrap: wrap;
	margin: 0 2rem;
}
ul{
	list-style: none;
	padding-inline-start: 0px;
}
.fter{
	background-color: #24262b;
}
.href {
	color: black;
    font-size: 2rem;
    padding: 5px;
}
.footer-col{
   width: 25%;
   /* padding: 0 15px; */
}
.footer-col h3{
	font-size: 19px;
	color: white;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li .href{
	font-size: 16px;
	text-transform: capitalize;
	text-decoration: none;
	font-weight: 600;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li .href:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links .href{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links .href:hover{
	color: #196162;
	background-color: #ffffff;
}


/**/
.szabaly5 {
	padding: 1rem 0;
	position: relative;
}
/*
.szabaly5::before {
    background-color: #23e6a8;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.szabaly5::after {
    background: linear-gradient( 45deg,#130f35,rgba(19, 15, 53, 0.46) );
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
*/
/**/


/**/
.kony5 {
	background-color: transparent;
	padding: 1rem 0;
}

.kony5 .footer-col .social-links .href:hover {
	color: #102223
}
/**/

/**/
.roadtrip {
	background: transparent;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
}

.roadtrip .footer-col .social-links .href:hover {
	color: #162333;
}

.roadtralt {
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
}

.roadtralt .footer-col .social-links .href:hover {
	color: #162333;
}
/**/

/**/
.szabaly5 .footer-col .social-links .href:hover {
	color: #1f324a;
}
/**/


@media(min-width: 575px){
	.footer-col ul li .href {
		width: 90%;
	}
}

@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}}

@media(max-width: 574px){
  .footer-col{
    width: 100%;
	text-align: center;
}}