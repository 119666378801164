.konyvwrapper {
    position: relative;
    max-width: 1190px;
    margin: 0 auto;
    padding: 0 2rem;
    flex-grow: 1;
}

.konyvwrapper h1 {
    font-size: 5rem;
    margin: 5rem 0;
    color: white;
}

.konyvwrapper hr {
    margin: 0;
    border-top: 1px solid #999;
}

.konyvcim {
    display: flex;
    justify-content: center;
    text-align: center;
}

.book h2 {
    font-size: 3.5rem;
    padding: 0 0 0 2rem;
    color: white;
    margin-bottom: 2rem;
}

.book {
    line-height: 1.75;
    margin-top: 1rem;
    margin-bottom: 5rem;
}

.herhet {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center center;
    object-fit: cover;
    image-rendering: auto;
    border-radius: 24px;
    inset: 0;
    opacity: 0.6;
    position: absolute;
    z-index: -1;
}

.kivetel123 {
    margin-top: 0 !important;
}

.book p {
    color: white;
    font-size: 18px;
    padding: 0 2rem;
}

.book pre {
    font-family: szabalypfont, sans-serif;
    color: #515260;
    font-size: 18px;
    padding: 0 2rem;
}

.shs54gfh4 img {
    width: 100%;
}

.sorozatdiv {
    padding: 0 2.5rem;
    margin-bottom: 7.5rem;
}

.sorozatdiv h3 {
    font-size: 2.3rem;
    margin-bottom: 0;
    padding: 0 2rem;
    color: white;
}

.a1b2ss {
    display: flex;
    flex-direction: row;
}

.fifty {
    display: flex;
    align-items: center;
    width: 72%;
}

.nembaj {
    width: 28%;
    display: flex;
    align-items: center;
}

.nembaj img {
    position: relative;
    top: -2.5rem;
    right: 1rem;
}

.kiv11122 {
    right: 0 !important;
}

.elsosorozat {
    margin-top: 3.5rem;
}

/*
.last8 {
    display: none; 
}
*/

.buzibogar {
    display: static;
}

.legbudosebb {
    display: none;
}

.budosebb {
    display: auto;
}

.budos {
    border: 1px solid;
    color: rgb(0, 0, 0);
    padding: 0.6875rem 1.5rem;
    cursor: pointer;
    border-radius: 1rem;
    border-color: rgba(0, 0, 0, 0.5);
    background: transparent;
    z-index: 9999;
    margin: 1rem;
}

.budos:hover {
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    transition: all 0.2s ease-out;
}

.morgan {
    margin-bottom: 0 !important;
}

.morgan {
    display: flex;
    align-items: center;
}

.morgan button {
    position: relative;
    top: 3px;
    margin-left: 0.8rem;

    border: 1px solid;
    color: white;
    padding: 0.6875rem 1.5rem;
    cursor: pointer;
    border-radius: 1rem;
    border-color: #82b6ff;
    background: #82b6ff;
    z-index: 9999;
    cursor: default;
}


.coloringer {
    position: relative;
    border-bottom-width: 2px;
    border-color: rgba(255, 255, 255, 0.04);
    border-left-width: 2px;
    border-right-width: 2px;
    border-style: solid;
    border-top-width: 2px;
    background-color: rgba(255, 255, 255, 0);
    height: 100%;
    width: 100%;
    border-radius: 24px;
    box-shadow:
        rgba(0, 0, 0, 0.03) 0px 1.34368px 1.34368px -0.46875px,
        rgba(0, 0, 0, 0.027) 0px 3.18477px 3.18477px -0.9375px,
        rgba(0, 0, 0, 0.027) 0px 5.80935px 5.80935px -1.40625px,
        rgba(0, 0, 0, 0.027) 0px 9.65802px 9.65802px -1.875px,
        rgba(0, 0, 0, 0.027) 0px 15.5969px 15.5969px -2.34375px,
        rgba(0, 0, 0, 0.024) 0px 25.5306px 25.5306px -2.8125px,
        rgba(0, 0, 0, 0.02) 0px 43.962px 43.962px -3.28125px,
        rgba(0, 0, 0, 0.01) 0px 80px 80px -3.75px,
        rgba(255, 255, 255, 0.145) 0px 0.48175px 0.48175px -1.25px inset,
        rgba(255, 255, 255, 0.125) 0px 1.83083px 1.83083px -2.5px inset,
        rgba(255, 255, 255, 0.05) 0px 8px 8px -3.75px inset;
    opacity: 1;
}

.bookshelf {
    margin-top: 5rem;
}



@media(max-width: 1015px) {
    .a1b2ss {
        flex-direction: column;
    }

    .fifty {
        width: auto;
    }

    .nembaj {
        padding-top: 2rem;
        width: auto;
        justify-content: center;
    }

    .nembaj img {
        right: 0;
        width: 357px;
    }

    .book h2 {
        padding: 0;
        text-align: center;
    }
}

@media(max-width: 450px) {
    .fifty p {
        font-size: 15px;
    }
}

@media(max-width: 750px) {
    .sorozatdiv {
        padding: 0;
    }
}


@media(max-width: 550px) {
    .book h2 {
        font-size: 3rem;
    }
}

@media(max-width: 470px) {
    .book h2 {
        font-size: 2.5rem;
    }
}

@media(max-width: 410px) {
    .book h2 {
        font-size: 2rem;
    }
}

@media(max-width: 450px) {
    .nembaj img {
        width: 300px;
    }
}

@media(max-width: 380px) {
    .nembaj img {
        width: 250px;
    }
}

@media(max-width: 330px) {
    .nembaj img {
        width: 200px;
    }
}

@media(max-width: 350px) {
    .morgan {
        flex-direction: column;
        align-items: flex-start;
    }
}

/*
@media(max-width: 300px) {
    .morgan {
        font-size: 2.5rem!important;
    }
    .kivetel111 h2{
        font-size: 2.5rem!important;
    }
}
*/
@media(max-width: 280px) {
    .nembaj img {
        width: 150px;
    }

    .konyvcim h1 {
        font-size: 4rem;
    }
}

@media(max-width: 250px) {
    .fifty p {
        font-size: 13px;
    }
}